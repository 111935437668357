import React, { useState, useEffect } from 'react';
import { Grid, styled, Box, Modal } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import '../styles/rain.css'


const StyledImg = styled('img')(({ isModalOpen }) => ({
  width: isModalOpen ? '95vw' : '100%',
  objectFit: 'cover',
  borderRadius: '5px',
  '@media (min-width: 1024px)': {
    width: isModalOpen ? '70vh' : '290px',
  },
}));

const CenteredGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const LazyImage = ({ image, index, handleImageClick, isModalOpen }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px',
  });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (inView) {
      const img = new Image();
      img.onload = () => {
        setIsLoaded(true);
      };
      img.src = image.default;
    }
  }, [image.default, inView]);

  return (
    <Grid item xs={4} sm={4} md={4} lg={3} key={index}>
      <div ref={ref}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <StyledImg
            src={image.default}
            alt=""
            onClick={() => handleImageClick(index)}
            isModalOpen={isModalOpen}
            style={{
              filter: isLoaded ? 'none' : 'blur(5px)',
            }}
            className='catImg'
          />
        </React.Suspense>
      </div>
    </Grid>
  );
};

const Rain = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    const importImages = async () => {
      const context = require.context('../assets/rain', false, /\.(png|jpe?g|svg)$/);
      const imagePaths = context.keys();
      const importedImages = await Promise.all(imagePaths.map(path => import(`../assets/rain/${path.replace('./', '')}`)));
      setImages(importedImages);
    };
    importImages();
  }, []);

  useEffect(() => {
    const loadImageAtIndex = (index) => {
      if (index >= images.length) return;

      const img = new Image();
      img.onload = () => {
        setLoadedImages(prevLoadedImages => [...prevLoadedImages, index]);
        setTimeout(() => {
          loadImageAtIndex(index + 1);
        }, 0); // Delay between loading each image (adjust as needed)
      };
      img.src = images[index].default;
    };

    loadImageAtIndex(0);
  }, [images]);

  const handleImageClick = (index) => {
    setSelectedImage(index);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const prioritizeImages = () => {
    if (selectedImage !== null) {
      const selectedImageObj = images[selectedImage];
      const remainingImages = images.filter((_, i) => i !== selectedImage);
      const sortedRemainingImages = remainingImages.sort((b, a) => {
        const aIndex = parseInt(a.default.match(/-?\d+/)[0]);
        const bIndex = parseInt(b.default.match(/-?\d+/)[0]);
        return bIndex - aIndex;
      });
      return [selectedImageObj, ...sortedRemainingImages];
    }
    return images.sort((b, a) => {
      const aIndex = parseInt(a.default.match(/-?\d+/)[0]);
      const bIndex = parseInt(b.default.match(/-?\d+/)[0]);
      return bIndex - aIndex;
    });
  };


  const sortedImages = prioritizeImages(); // Reverse the order of images

  return (
    <div className="rain-background">
      <Box sx={{ bgcolor: 'transparent', justifyContent: 'center', textAlign: 'center', paddingBottom: '20px' }}>
        <CenteredGrid container spacing={2}>
          {sortedImages.map((image, index) => (
            <LazyImage
              image={image}
              index={index}
              handleImageClick={handleImageClick}
              key={index}
              isModalOpen={isModalOpen}
            />
          ))}
        </CenteredGrid>
        {selectedImage !== null && (
          <Modal onClick={handleCloseModal} open={isModalOpen} onClose={handleCloseModal} style={{ backgroundColor: 'black' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <StyledImg
                onClick={handleCloseModal}
                src={images[selectedImage].default}
                alt=""
                isModalOpen={isModalOpen}
              />
            </Box>
          </Modal>
        )}
      </Box>
    </div>
  );
};

export default Rain;
