import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import NavbarComponent from './components/Navbar';
import Footer from './components/Footer';
import Nude from './pages/Nude';
import Categories from './pages/Categories';
import OnLocation from './pages/OnLocation';
import Other from './pages/Other';
import Portrait from './pages/Portrait';
import PublishedWork from './pages/PublishedWork';
import Studio from './pages/Studio';
import AboutUs from './pages/AboutUs';
import Booking from './pages/Booking';
import FineArt from './pages/FineArt';
import Rain from './pages/Rain';
import Contact from './pages/Contact';
import RedChair from './pages/RedChair';

function App() {
  const [isOver18, setIsOver18] = useState(false);

  useEffect(() => {
    const isOver18Cookie = localStorage.getItem('isOver18');
    if (isOver18Cookie) {
      setIsOver18(true);
    }
  }, []);

  const handleOver18Confirmation = () => {
    localStorage.setItem('isOver18', 'true');
    setIsOver18(true);
  };

  return (
    <Container fluid style={{ color: 'black', backgroundColor: 'black', minHeight: '100vh' }}>
      {!isOver18 && (
        <div className="confirmation">
          <h2>18+ Confirmation</h2>
          <p>Please confirm that you are 18 years or older to proceed.</p>
          <button className="confirmation-button" onClick={handleOver18Confirmation}>I am 18+</button>
        </div>
      )}
      {isOver18 && (
        <>
          <NavbarComponent />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/nude" element={<Nude />} />
              <Route path="/gallery" element={<Categories />} />
              <Route path="/onlocation" element={<OnLocation />} />
              <Route path="/other" element={<Other />} />
              <Route path="/portrait" element={<Portrait />} />
              <Route path="/publishedwork" element={<PublishedWork />} />
              <Route path="/studio" element={<Studio />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/booking" element={<Booking />} />
              <Route path="/fineart" element={<FineArt />} />
              <Route path="/rain" element={<Rain />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/redchair" element={<RedChair />} />
            </Routes>
          </BrowserRouter>
          <Footer />
        </>
      )}
    </Container>
  );
}

export default App;
