import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import '../styles/slideshow.css';

const Slideshow = () => {
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [nextImage, setNextImage] = useState(1);

  useEffect(() => {
    const importImages = async () => {
      const context = require.context('../assets/slideshow', false, /\.(png|jpe?g|svg)$/);
      const imagePaths = context.keys();
      const importedImages = await Promise.all(imagePaths.map(path => import(`../assets/slideshow/${path.replace('./', '')}`)));
      setImages(importedImages);
    };
    importImages();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentImage((currentImage + 1) % images.length);
      setNextImage((currentImage + 2) % images.length);
    }, 1100);
    return () => clearTimeout(timer);
  }, [currentImage, images]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className="slideshow-container">
            {images.length > 0 && (
              <>
                <img
                  key={currentImage}
                  className="slideshow-image active"
                  src={images[currentImage].default}
                  alt=""
                  onClick={() => (window.location.href = '/gallery')}
                />
                <img
                  key={nextImage}
                  className="slideshow-image"
                  src={images[nextImage].default}
                  style={{ display: 'none' }}
                  alt=""
                />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Slideshow;
