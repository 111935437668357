import React from 'react';
import '../styles/contact.css'

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: '20px'
};

const headingStyle = {
  fontSize: '36px',
  color: 'white',
  marginBottom: '20px',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  marginTop: '12vh',

};

const contactInfoStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '20px',
};

const contactTextStyle = {
  fontSize: '24px',
  color: 'white',
  marginBottom: '8px',
};

const contactLinkStyle = {
  fontSize: '20px',
  color: 'white',
  textDecoration: 'none',
  transition: 'color 0.3s',
};

const Contact = () => {
  return (
    <div className="con-background">

      <div style={containerStyle}>
        <h2 style={headingStyle}>Contact Us</h2>
        <div style={contactInfoStyle}>
          <p style={contactTextStyle}><b>Email:</b></p>
          <a href="mailto:hoytimages@yahoo.com" style={contactLinkStyle}><u>hoytimages@yahoo.com</u></a>
        </div>
        <div style={contactInfoStyle}>
          <p style={contactTextStyle}><b>Phone:</b></p>
          <a href="tel:+15105044409" style={contactLinkStyle}><u>+1 (510) 504-4409</u></a>
        </div>
        <div style={contactInfoStyle}>
          <p style={contactTextStyle}><b>Instagram</b></p>
          <a href="https://www.instagram.com/hoyt_images/?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" style={contactLinkStyle}><u>@hoyt_images</u></a>
        </div>
        <div style={contactInfoStyle}>
          <p style={contactTextStyle}><b>Model Mayhem:</b></p>
          <a href="https://www.modelmayhem.com/4253036" style={contactLinkStyle}><u>#4253036</u></a>
        </div>
      </div>
    </div>

  );
};

export default Contact;
