import React from "react";
import Slideshow from "../components/SlideShow";
import '../styles/home.css';  // Make sure the path to the CSS file is correct

function Home() {
    return (
        <div>
            <Slideshow />
        </div>
    );
}

export default Home;
