import { Navbar, NavbarBrand } from 'react-bootstrap';
import Button from '@mui/material/Button';
import React from 'react';
import Logo from '../assets/Logo.png';
import '../styles/navbar.css';
import { ThemeProvider, createTheme } from "@mui/material/styles";



const theme3 = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#ffff',
      darker: '#80808080',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  typography: {
    fontSize: 18, // Change this value to adjust the font size

  },

});


function NavbarComponent() {

  return (
    <>
      <Navbar expand="lg">
        <NavbarBrand href='/'>
          <img src={Logo} alt='logo' className='logo2'></img>
        </NavbarBrand>

        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <ThemeProvider theme={theme3}>
            <Button color='primary' onClick={() => window.location.href = '/gallery'}><b>Gallery</b></Button>
          </ThemeProvider>
          <ThemeProvider theme={theme3}>
            <Button color='primary' onClick={() => window.location.href = '/about'} ><b>About</b></Button>
          </ThemeProvider>
          <ThemeProvider theme={theme3}>
            <Button color='primary' onClick={() => window.location.href = '/booking'} ><b>Booking</b></Button>
          </ThemeProvider>
          <ThemeProvider theme={theme3}>
            <Button color='primary' onClick={() => window.location.href = '/contact'} ><b>Contact</b></Button>
          </ThemeProvider>
        </Navbar.Collapse>
      </Navbar>

    </>
  );
};

export default NavbarComponent;
