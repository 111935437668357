import React from 'react';
import '../styles/booking.css'

const containerStyle = {
  backgroundColor: 'transparent',
  padding: '40px',
  borderRadius: '8px',
  paddingTop: '60px',
};

const headingStyle = {
  fontSize: '26px',
  color: 'white',
  marginBottom: '20px',
};

const textStyle = {
  fontSize: '18px',
  color: 'white',
  marginBottom: '16px',
};

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#ff4081',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  fontSize: '16px',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  marginRight: '10px'

};

const Booking = () => {
  return (
    <div className="boo-background">
      <div style={containerStyle}>
        <h2 style={headingStyle}>Booking</h2>
        <p style={textStyle}>
          Contact us with your needs & we’ll discuss the appropriate course of action. Each assignment is individual, therefore it will have its own unique parameters.      </p>
        <p style={textStyle}>
          Check our gallery for inspiration, then go to the contact page & let us know what you need. Excited to work with you!      </p>
        <p style={textStyle}>
          To book an appointment reach out using the information on our contact page.     </p>

        <button onClick={() => window.location.href = '/contact'} style={buttonStyle}>Contact Us</button>

      </div>
    </div>

  );
};

export default Booking;
