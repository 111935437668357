import React from 'react';
import '../styles/about.css'

const containerStyle = {
  backgroundColor: 'transparent',
  padding: '40px',
  borderRadius: '8px',
  paddingTop: '60px',
};

const headingStyle = {
  fontSize: '26px',
  color: 'white',
  marginBottom: '20px',
};

const textStyle = {
  fontSize: '18px',
  color: 'white',
  marginBottom: '16px',
};

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#ff4081',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  fontSize: '16px',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  marginRight: '10px'

};

const buttonStyle2 = {
  padding: '10px 20px',
  backgroundColor: '#ff4081',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  fontSize: '16px',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const AboutUs = () => {
  return (
    <div className="ab-background">
      <div style={containerStyle}>
        <h2 style={headingStyle}>About Us</h2>
        <p style={textStyle}>
          At Hoyt Images we don’t just take pictures, we create art by capturing a moment in time.
        </p>
        <p style={textStyle}>
          We work with professionals, the latest equipment, & employ a unique approach to artistic interpretation of the female form.
        </p>
        <p style={textStyle}>
          We strive to be innovative & creative, developing images that are as timeless as fine art.
        </p>
        <p style={textStyle}>
          We are established in the San Francisco bay area & have successfully published through a variety of mediums.
        </p>
        <p style={textStyle}>
          We strive to create the perfect theme for our clients, with the option to integrate their ideas efficiently.
        </p>
        <p style={textStyle}>
          Reach out to collaborate on an artistic project in a thoughtful, professional, & inspirational environment.
        </p>
        <button onClick={() => window.location.href = '/contact'} style={buttonStyle}>Contact Us</button>
        <button onClick={() => window.location.href = '/booking'} style={buttonStyle2}>Booking</button>

      </div>
    </div>
  );
};

export default AboutUs;
