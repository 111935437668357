import React, { useState, useEffect } from 'react';
import { Grid, styled, Box, Typography } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import p1 from '../assets/categories/1.jpeg';
import p2 from '../assets/categories/2.jpeg';
import p3 from '../assets/categories/3.jpeg';
import p4 from '../assets/categories/4.jpeg';
import p5 from '../assets/categories/5.jpeg';
import p6 from '../assets/categories/6.jpeg';
import p7 from '../assets/categories/7.jpeg';
import p8 from '../assets/categories/8.jpeg';
import p9 from '../assets/categories/9.jpeg';
import p10 from '../assets/categories/10.jpeg';
import '../styles/gal.css'

const CenteredGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '20px',
});

const CategoryItem = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  position: 'relative',
  cursor: 'pointer',
});

const CategoryImage = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  borderRadius: '5px',
});

const CategoryOverlay = styled(Box)({
  position: 'absolute',
  bottom: '0',
  left: '0', // Changed from '7px' to '0'
  width: '100%', // This ensures the overlay matches the image width
  padding: '10px',
  boxSizing: 'border-box',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: '#fff',
  textAlign: 'center',
  borderBottomLeftRadius: '5px',  // Corrected property name
  borderBottomRightRadius: '5px',  // Corrected property name
});


const CategoryHeading = styled(Typography)({
  margin: '0',
  fontSize: '20px',

});

const LazyImage = ({ src, alt, caption, onClick }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px',
  });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (inView) {
      const img = new Image();
      img.onload = () => {
        setIsLoaded(true);
      };
      img.src = src;
    }
  }, [src, inView]);

  return (
    <CategoryItem item xs={6} sm={6} md={4} lg={3} onClick={onClick}>
      <div ref={ref} style={{ position: 'relative', width: '100%', height: '400px' }}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <CategoryImage
            src={src}
            alt={alt}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '5px',
              transition: 'filter 0.3s ease',
              filter: isLoaded ? 'none' : 'blur(5px)',
            }}
          />
          <CategoryOverlay>
            <CategoryHeading variant="body2">{caption}</CategoryHeading>
          </CategoryOverlay>
        </React.Suspense>
      </div>
    </CategoryItem>
  );
};


const Categories = () => {
  const categories = [
    {
      src: p6,
      alt: 'Studio',
      caption: 'Studio',
      onClick: () => (window.location.href = '/studio'),
    },
    {
      src: p2,
      alt: 'On Location',
      caption: 'On Location',
      onClick: () => (window.location.href = '/onlocation'),
    },
    {
      src: p10,
      alt: 'Rain',
      caption: 'Rain',
      onClick: () => (window.location.href = '/rain'),
    },
    {
      src: p4,
      alt: 'Red Chair',
      caption: 'Red Chair',
      onClick: () => (window.location.href = '/redchair'),
    },
    {
      src: p9,
      alt: 'Nudes',
      caption: 'Nudes',
      onClick: () => (window.location.href = '/nude'),
    },
    {
      src: p8,
      alt: 'Portraits',
      caption: 'Portraits',
      onClick: () => (window.location.href = '/portrait'),
    }, {
      src: p5,
      alt: 'Fine Art',
      caption: 'Fine Art',
      onClick: () => (window.location.href = '/fineart'),
    }, {
      src: p3,
      alt: 'Published',
      caption: 'Published',
      onClick: () => (window.location.href = '/publishedwork'),
    },
    {
      src: p1,
      alt: 'Other',
      caption: 'Other',
      onClick: () => (window.location.href = '/other'),
    },
    // Add 
    // Add more image objects as needed
  ];

  return (
    <div className="gal-background">

      <Box sx={{ bgcolor: 'transparent', justifyContent: 'center', textAlign: 'center' }}>
        <CenteredGrid container spacing={2}>
          {categories.map((category, index) => (
            <LazyImage
              key={index}
              src={category.src}
              alt={category.alt}
              caption={category.caption}
              onClick={category.onClick}
            />
          ))}
        </CenteredGrid>
      </Box>
    </div>
  );
};

export default Categories;
